//* Variables ==================================== *//

/* Media Queries & Breakpoints
	https://include-media.com/
	=========================================== */

/*
| Name       | Code      | Short-Code | Em        | Px     |
| -----------|-----------|------------|-----------|--------|
| X-Small    | xsmall    | xs         | 23.4375em | 375px  |
| Small      | small     | s          | 40em      | 640px  |
| Medium     | medium    | m          | 48em      | 768px  |
| Large      | large     | l          | 60em      | 960px  |
| X-Large    | xlarge    | xl         | 75em      | 1200px |
| XX-Large   | xxlarge   | xxl        | 90em      | 1440px |
| XXX-Large  | xxxlarge  | xxxl       | 100em     | 1600px |
*/

// Breakpoints
$breakpoints-xsmall:	23.4375em;
$breakpoints-small:		40em;
$breakpoints-medium:	48em;
$breakpoints-large:		60em;
$breakpoints-xlarge:	75em;
$breakpoints-xxlarge:	90em;
$breakpoints-xxxlarge:	100em;

// Wrappers
$wrappers-site:			100vw;
$wrappers-default:		1600px;
$wrappers-wide:			1920px;
$wrappers-ultrawide:	2560px;

// Wrappers: Padding
$wrapper-padding-small:		16px;
$wrapper-padding-medium:	26px;
$wrapper-padding-large:		32px;
$wrapper-padding-xxlarge:	30px;
$wrapper-padding-xxxlarge:	26px;

// Fonts: Family
$font-family-heading: ("din-condensed", Helvetica, Arial, sans-serif);
$font-family-copy: ("Inter", "Inter-fallback", Arial, sans-serif);
$font-family-cta: ("Inter", "Inter-fallback", Arial, sans-serif);

$font-family-primary: $font-family-copy;
$font-family-secondary: $font-family-cta;

// Fonts: Icons
@import "extensions/icons/lib/webfont/variables";
$font-family-icons: ($icomoon-font-family, sans-serif);

// Fonts: Base Settings
$html-font-size: 100%;
$font-size: 16px;

// Fonts: Size
$font-size-xsmall: 12px;
$font-size-small:  14px;
$font-size-medium: 16px;
$font-size-large:  24px;
$font-size-xlarge: 32px;
$line-height: 1.25;

// Fonts: Weights (https://gist.github.com/steveosoule/8275c2dd2ec21388aaf319b5d2cd5a71#bigelowandholmestypepadcombigelow-holmes201507on-font-weighthtml)
$font-weight-thin: 			100;
$font-weight-extralight:    200;
$font-weight-light:     	300;
$font-weight-normal:    	400;
$font-weight-medium:    	500;
$font-weight-semibold:  	600;
$font-weight-bold:      	700;
$font-weight-extrabold:     800;
$font-weight-black:     	900;

// Typography: Headings
$heading-h1-size: 60px;
$heading-h2-size: 48px;
$heading-h3-size: 40px;
$heading-h4-size: 32px;
$heading-h5-size: 26px;
$heading-h6-size: 20px;
$heading-line-height: $line-height;
$heading-font-weight: $font-weight-normal;

// Typography: Subeadings
$subheading-font-size-xsmall: 18px;
$subheading-font-size-small: 24px;
$subheading-font-size-large: 32px;
$subheading-font-weight: $font-weight-normal;

// Typography: Paragraph
$paragraph-font-size-small: $font-size-small;
$paragraph-font-size-large: $font-size;

// Typography: Message
$message-1-font-size: $font-size-xsmall;

// Typography: Pricing
$price-font-size-xsmall: 13px;
$price-font-size-small: $font-size;
$price-font-size-large: 18px;
$price-font-weight: $font-weight-normal;

// Typography: Call To Action
$call-to-action-font-size: $font-size-small;
$call-to-action-font-weight: $font-weight-semibold;

// Typography: Navigation
$navigation-font-size: $font-size-small;
$navigation-font-weight: $font-weight-medium;

// Typography: Captions
$captions-font-size-small: 11px;
$captions-font-size-large: 13px;
$captions-font-weight: $font-weight-semibold;

// Typography: Form Labels
$form-label-font-size: $font-size-xsmall;
$form-label-font-weight: $font-weight-medium;

// Colors: Dark/Light
$light-fill-color: #F6F2F8;
$text-bg-fill-color: #F8F6FA;
$dark-hover-color: #ACB5C1;

// Colors: Primary (Theme)
$primary: #403455;
$secondary: #806395;
$secondary-light: #D1CDD8;
$tertiary: $light-fill-color;
$tertiary-dark: #CA81A6;
$brand: #2E3556;

// Colors: Secondary
$white: #FFFFFF;
$black: #222222;

$gray-6: #2B3036;
$gray-5: #4D4F50;
$gray-4: #909091;
$gray-3: #CCCCCC;
$gray-2: #EAEAEA;
$gray-1: #F5F5F5;

$transparent: rgba(0, 0, 0, 0);

// Colors: Text
$main-text-color: #4D4F50;

// Colors: Pricing
$price-color: #222222;
$base-price-color: #767676;
$sale-price-color: #B13138;

// Colors: Text
$text-color-xlight: $gray-1;
$text-color-light: $gray-2;
$text-color-medium: $gray-3;
$text-color-dark: $gray-4;
$text-color-xdark: $gray-5;
$text-color-xxdark: $gray-6;

// Colors: Border
$border-color-xlight: $gray-1;
$border-color-light: $gray-2;
$border-color-medium: $gray-3;
$border-color-dark: $gray-4;
$border-color-xdark: $gray-5;
$border-color-xxdark: $gray-6;

// Colors: Backgrounds
$background-color-xlight: $gray-1;
$background-color-light: $gray-2;
$background-color-medium: $gray-3;
$background-color-dark: $gray-4;
$background-color-xdark: $gray-5;
$background-color-xxdark: $gray-6;

// Colors: Messages
$message-error-text: #B13138;
$message-error-border: #F9C4C7;
$message-error-background: #FBE3E3;

$message-warning-text: #65602E;
$message-warning-border: #E6E086;
$message-warning-background: #F8F8E1;

$message-success-text: #0A7448;
$message-success-border: #BBE1D3;
$message-success-background: #DAF5EB;

$message-info-text: #0C5298;
$message-info-border: #8FBBE6;
$message-info-background: #D8EBFF;

$message-neutral-text: #4D4F50;
$message-neutral-border: #767676;
$message-neutral-background: #FFFFFF;

$message-font-color: #FFFFFF;
$message-danger-color: #B13138;
$message-discount-color: #B13138;

// Border: Radius
$radius-none:   0;
$radius-small:  3px;
$radius-medium: 4px;
$radius-large:  160px;

// Border: Width
$border-width-none:   0;
$border-width-small:  1px;
$border-width-medium: 3px;
$border-width-large:  4px;

// Border: Styles
$border-solid: $border-width-small solid $border-color-light;

// Messages
$message-border: $border-width-small solid currentColor;
$message-border-radius: $radius-medium;

// Box-Shadows
$shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
$shadow-inset: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);

// Outline
$outline: 0;

// Animation
$transition-time: 0.25s;
$transition-easing: ease-in-out;
$transition: all $transition-time $transition-easing;

// Input
$input-autofill-background-color: #F4F7FD;
$input-autofill-border-color:     $tertiary-dark;
$input-background-color:          #FFFFFF;
$input-border:                    $border-solid;
$input-color:                     #4D4F50;
$input-disabled-background-color: #FAFAFA;
$input-disabled-color:            #CCCCCC;
$input-focus-color:               #4D4F50;
$input-outline:                   $outline;
$input-radius:                    $radius-small;
$input-shadow-active:             none;
$input-shadow:                    $shadow;
$input-transition:                left $transition-time ease, opacity $transition-time ease, border-color $transition-time ease, background-color $transition-time ease;

// Input: Heights
$input-height-small:	40px;
$input-height:			50px;
$input-height-large:	64px;
$input-height-huge:		80px;

// Keylines
$keyline-border: $input-border;

// Units
$unit:				16px;
$unit-xxsmall:		$unit * 0.125; // 2px
$unit-xsmall:		$unit * 0.25;  // 4px
$unit-small:		$unit * 0.5;   // 8px
$unit-medium:		$unit * 1.5;   // 24px
$unit-large:		$unit * 2;     // 32px
$unit-xlarge:		$unit * 4;     // 64px
$unit-xxlarge:		$unit * 5;     // 80px
$unit-xxxlarge:		$unit * 6;     // 96px

// Units: Columns
$column-count: 12;
$gutter-width: $unit;
$gutter-unit:  $gutter-width * 0.5;

// Spacers
$spacer-1:	5px;
$spacer-2:	10px;
$spacer-3:	20px;
$spacer-4:	25px;
$spacer-5:	30px;
$spacer-6:	50px;
$spacer-7:	60px;
$spacer-8:	80px;
$spacer-9:	100px;
$spacer-10:	130px;

// Tables
$table-border:				$input-border;
$table-stripe-color:		#F5F5F5;
$table-row-padding:			$unit-small;
$table-cell-padding:		$unit-small;
$table-cell-padding-large:	$unit;
$table-cell-padding-xlarge:	$unit-large;

// Z-indexes
$layers-max:	9999;
$layers-min:	-9999;
$layers-n1:		-1;
$layers-0:		0;
$layers-1:		100;
$layers-2:		200;
$layers-3:		300;
$layers-4:		400;

// Generic Cards
$card-height:				100%;
$card-bg:					$background-color-xlight;
$card-border-width:			0;
$card-border-color:			#DDDDDD;
$card-border:				$card-border-width solid $card-border-color;
$card-border-radius:		$radius-large;
$card-spacer-y:				$unit;
$card-spacer-x:				$unit;
$card-cap-bg:				#DDDDDD;
$card-inner-border-radius:	$radius-large;
$nav-tabs-link-active-bg:	#DDDDDD;
$enable-rounded:			1;

// Product Cards
$rating-color: #FFD700;
$box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);

// Buttons
$button-font-size:			$font-size;
$button-font-family:		$font-family-cta;
$button-font-weight:		$font-weight-semibold;
$button-radius:				$radius-medium;

// Links
$link-color:				#4D4F50;
$link-hover-color:			$secondary;
$link-active-color:			$secondary;
$link-focus-color:			$link-color;
$link-focus-border-color:	$tertiary-dark;
$link-transition:			all $transition-time cubic-bezier(0.25, 0.8, 0.25, 1);

// Carousel
$ui-carousel-arrow-size: 45px;
$ui-carousel-icon-size: 11px;

// Container
$ui-container-spacing-xs: 	$unit * 2;
$ui-container-spacing-s: 	$unit * 3;
$ui-container-spacing-m: 	$unit * 4;
$ui-container-spacing-l: 	$unit * 6;

// Global Elements
$sticky-header-box-shadow: 0 7px 16px rgba(0, 0, 0, 0.04);
