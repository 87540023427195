//* Link Mixins ==================================== *//

@mixin base-link-hover ($hover-color: $link-hover-color) {
	color: $hover-color;
}

@mixin base-link($color: $link-color, $hover-color: $link-hover-color, $active-color: $link-active-color, $focus-color: $link-focus-color, $focus-border-color: $link-focus-border-color, $font-size: $font-size-small, $font-weight: $font-weight-normal) {
	color: $color;
	cursor: pointer;
	display: inline-block;
	text-decoration: underline;
	font: {
		size: $font-size;
		weight: $font-weight;
	};

	@media ( prefers-reduced-motion: no-preference ) {
		transition: $link-transition;
	}

	&:hover {
		@include base-link-hover($hover-color: $hover-color);
	}

	&:active {
		color: $active-color;
	}

	&:focus:not(:active):not(:hover) {
		border-radius: 2px;
		box-shadow: 0 0 0 1px $focus-border-color;
		color: $focus-color;
		outline: 0;
		transition: none;
	}
}

@mixin regular-link {
	@include base-link(
		$color: $secondary,
		$hover-color: $primary,
		$active-color: $primary,
		$focus-color: $secondary,
		$font-weight: $font-weight-semibold
	);
	position: relative;
}

@mixin display-link-hover ($hover-color: $link-hover-color) {
	color: $hover-color;
}

@mixin display-link {
	@include base-link(
		$color: $secondary,
		$hover-color: $primary,
		$active-color: $primary,
		$focus-color: $secondary,
		$font-weight: $font-weight-semibold
	);
}

//* Input Mixins ==================================== *//

@mixin input-border-style {
	border: $input-border;
	border-radius: $input-radius;
	box-shadow: $input-shadow;
	outline: $input-outline;

	@media ( prefers-reduced-motion: no-preference ) {
		transition: $input-transition;
	}
}

@mixin input-style ($height) {
	@include input-border-style;
	appearance: none;
	background-clip: padding-box;
	background-color: $input-background-color;
	color: $input-color;
	display: inline-block;
	height: $height;
	line-height: $height;
	margin-bottom: $unit-xsmall;
	padding: 0 $unit;
	width: 100%;

	&:active,
	&:focus {
		border-color: $input-focus-color;
		outline: 0;
	}

	&:autofill,
	&:-webkit-autofill {
		border-color: $input-autofill-border-color;
		box-shadow: 0 0 0 $height $input-autofill-background-color inset;
	}
}

@mixin dropdown-style {
	@include input-border-style;
	appearance: none;
	background-color: $input-background-color;
	color: $input-color;
	cursor: pointer;
	height: $input-height;
	padding: $unit-small $unit;
	width: 100%;

	&::-ms-expand {
		display: none;
	}

	&:focus {
		border-color: $input-focus-color;
		box-shadow: $input-shadow-active;
		outline: $input-outline;
	}

	&:autofill,
	&:-webkit-autofill {
		border-color: $input-autofill-border-color;
		box-shadow: 0 0 0 $input-height $input-autofill-background-color inset;
	}
}

//* Typography Mixins ==================================== *//

@mixin type-heading ($color: $black, $font-weight: $heading-font-weight, $text-transform: uppercase) {
	color: $color;
	font-family: $font-family-heading;
	font-weight: $font-weight;
	text-transform: $text-transform;
}

@mixin type-display-1-style ($line-height: 80px, $letter-spacing: 25) {
	font-size: 80px;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-display-1 {
	@include type-heading();
	@include type-display-1-style();
}

@mixin type-display-2-style ($line-height: 60px, $letter-spacing: 25) {
	font-size: $heading-h1-size;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-display-2 {
	@include type-heading();
	@include type-display-2-style();
}

@mixin type-display-3-style ($line-height: 48px, $letter-spacing: 25) {
	font-size: $heading-h2-size;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-display-3 {
	@include type-heading();
	@include type-display-3-style();
}

@mixin type-title-1-style ($line-height: 40px, $letter-spacing: 25) {
	font-size: $heading-h3-size;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-title-1 {
	@include type-heading();
	@include type-title-1-style();
}

@mixin type-title-2-style ($line-height: 32px, $letter-spacing: 25) {
	font-size: $heading-h4-size;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-title-2 {
	@include type-heading();
	@include type-title-2-style();
}

@mixin type-title-3-style ($line-height: 28px, $letter-spacing: 25) {
	font-size: $heading-h5-size;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-title-3 {
	@include type-heading();
	@include type-title-3-style();
}

@mixin type-title-4-style ($line-height: 22px, $letter-spacing: 50) {
	font-size: $heading-h6-size;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-title-4 {
	@include type-heading();
	@include type-title-4-style();
}

@mixin type-subheading ($color: $secondary, $font-weight: $subheading-font-weight, $text-transform: uppercase) {
	color: $color;
	font-family: $font-family-heading;
	font-weight: $font-weight;
	text-transform: $text-transform;
}

@mixin type-subheading-large-style ($line-height: 32px, $letter-spacing: 50) {
	font-size: $subheading-font-size-large;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-subheading-large {
	@include type-subheading();
	@include type-subheading-large-style();
}

@mixin type-subheading-small-style ($line-height: 26px, $letter-spacing: 50) {
	font-size: $subheading-font-size-small;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-subheading-small {
	@include type-subheading();
	@include type-subheading-small-style();
}

@mixin type-subheading-xsmall-style ($line-height: 22px, $letter-spacing: 50) {
	font-size: $subheading-font-size-xsmall;
	line-height: $line-height;
	letter-spacing: xd-letter-spacing-to-px($letter-spacing);
}

@mixin type-subheading-xsmall {
	@include type-subheading();
	@include type-subheading-xsmall-style();
}

@mixin type-paragraph ($color: $main-text-color) {
	color: $color;
}

@mixin type-paragraph-large-style ($line-height: 22px) {
	font-size: $paragraph-font-size-large;
	line-height: $line-height;
}

@mixin type-paragraph-large {
	@include type-paragraph();
	@include type-paragraph-large-style();
}

@mixin type-paragraph-small-style ($line-height: 20px) {
	font-size: $paragraph-font-size-small;
	line-height: $line-height;
}

@mixin type-paragraph-small {
	@include type-paragraph();
	@include type-paragraph-small-style();
}

@mixin type-message-1-style ($line-height: 18px) {
	font-size: $message-1-font-size;
	line-height: $line-height;
}

@mixin type-message-1 {
	@include type-paragraph();
	@include type-message-1-style();
}

@mixin type-price($color: $price-color, $font-weight: $price-font-weight) {
	color: $color;
	font-weight: $font-weight;
}

@mixin type-price-large-style ($line-height: 24px) {
	font-size: $price-font-size-large;
	line-height: $line-height;
}

@mixin type-price-large {
	@include type-price();
	@include type-price-large-style();
}

@mixin type-price-small-style ($line-height: 22px) {
	font-size: $price-font-size-small;
	line-height: $line-height;
}

@mixin type-price-small {
	@include type-price();
	@include type-price-small-style();
}

@mixin type-price-xsmall-style ($line-height: 22px) {
	font-size: $price-font-size-xsmall;
	line-height: $line-height;
}

@mixin type-price-xsmall {
	@include type-price();
	@include type-price-xsmall-style();
}

@mixin type-call-to-action ($color: $main-text-color, $line-height: 23px) {
	color: $color;
	font: {
		size: $call-to-action-font-size;
		weight: $call-to-action-font-weight;
	}
	line-height: $line-height;
}

@mixin type-navigation ($color: $black, $line-height: 19px) {
	color: $color;
	font: {
		size: $navigation-font-size;
		weight: $navigation-font-weight;
	}
	line-height: $line-height;
}

@mixin type-captions ($color: $black, $text-transform: uppercase) {
	color: $color;
	font-weight: $captions-font-weight;
	letter-spacing: xd-letter-spacing-to-px(60);
	text-transform: $text-transform;
}

@mixin type-captions-large-style ($line-height: 16px) {
	font-size: $captions-font-size-large;
	line-height: $line-height;
}

@mixin type-captions-large {
	@include type-captions();
	@include type-captions-large-style();
}

@mixin type-captions-small-style ($line-height: 14px) {
	font-size: $captions-font-size-small;
	line-height: $line-height;
}

@mixin type-captions-small {
	@include type-captions(
		$color: $main-text-color
	);
	@include type-captions-small-style();
}

@mixin type-form-label ($color: $black, $line-height: 13px) {
	color: $color;
	font-family: $font-family-cta;
	font-size: $form-label-font-size;
	font-weight: $form-label-font-weight;
	line-height: $line-height;
}

//* Image Functionality Mixins ==================================== *//

@mixin image-container-center($width: 100px, $height: 100px, $align-items: center, $justify-content: center, $margin: auto) {
	align-items: $align-items;
	display: flex;
	height: $height;
	justify-content: $justify-content;
	margin: $margin;
	width: $width;
}

//* Account Page Mixins ==================================== *//

@mixin account-link-hover {

	&:not(.g-display-link):not(.g-regular-link) {
		color: $primary;
	}
}

//* Product Card Mixins ==================================== *//

@mixin product-card-image-container($background-color: $transparent, $height: 0, $padding-bottom: 100%, $position: relative) {
	background-color: $background-color;
	height: $height;
	padding-bottom: $padding-bottom;
	position: $position;
}

@mixin product-card-image-position($left: 50%, $max-height: 100%, $position: absolute, $top: 50%, $transform: translate(-50%, -50%), $width: auto) {
	left: $left;
	max-height: $max-height;
	position: $position;
	top: $top;
	transform: $transform;
	width: $width;
}

//* Scrollbar Mixins ==================================== *//

@mixin scrollbar-vertical ($scrollbar-color: $gray-1, $scrollbar-border-radius: 2px, $scrollbar-width: 3px, $scrollbar-thumb-color: $border-color-medium) {

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
	}

	&::-webkit-scrollbar {
		border-radius: $scrollbar-border-radius;
		width: $scrollbar-width;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-thumb-color;
	}
}

@mixin scrollbar-horizontal ($scrollbar-color: transparent, $scrollbar-border-radius: 20px, $scrollbar-height: 2px, $scrollbar-thumb-color: $border-color-light) {

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		border-radius: $scrollbar-border-radius;
	}

	&::-webkit-scrollbar {
		height: $scrollbar-height;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-thumb-color;
		border-radius: $scrollbar-border-radius;
	}
}

//* Breakpoint Mixins ==================================== *//

@mixin share-breakpoints($map , $cssprop: content) {
	$description: "";

	@each $property, $value in $map {
		@if $description !=  "" {
			$description: $description + " | ";
		}
		$description: $description + "--" + $property + ": (max-width: " + inspect($value) + ")";
	}

	display: none;
	#{$cssprop}: $description;
}

//* Brand Mixin ==================================== *//

@mixin product-brand($color: $main-text-color, $display: block, $font-size: 13px, $line-height: 15px, $margin-bottom: $unit-xsmall) {
	color: $color;
	display: $display;
	font-size: $font-size;
	line-height: $line-height;
	margin-bottom: $margin-bottom;
}
